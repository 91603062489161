.background-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -1;
  }
  
  .background-image {
    width: 100%;
    height: 100%;
    object-fit: cover; /* S'assure que l'image s'adapte bien */
    transition: transform 0.1s ease-out; /* Pour lisser l'animation */
  }
  